import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"
import {Helmet} from "react-helmet";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export const PortfolioPage=()=>{
   const [portfoliodata,sePortfoliodata]=useState();
   console.log('portfoliodata',portfoliodata);
   const [category, setCategory] = useState('All');
   const [largePic,setLargePic]=useState(null);
   const [visibleImages, setVisibleImages] = useState(24); 
   const [open, setOpen] = useState(false);

   const onOpenModal = () => setOpen(true);
   const onCloseModal = () => setOpen(false);// State to manage the number of visible images

   const handleLoadMore = () => {
     setVisibleImages(prevVisibleImages => prevVisibleImages + 12);
   };


   const filteredPortfolio = category === 'All' ? portfoliodata : portfoliodata.filter(item => item.name === category);

    const PortfolioRes=async()=>{
      try{
       const res=await axios.get('https://shop.digitize4life.com/api/v2/portfolio');
       sePortfoliodata(res.data.portfolio);
       console.log('portfolio',res.data.portfolio)
      }catch(err){
        console.log('err',err);
      }
    }
    useEffect(()=>{
      PortfolioRes()
    },[])

    const handleModalClose = (event) => {
      if (event.target.classList.contains('modalContainer')) {
          setLargePic(null);
      }
  };

  useEffect(() => {
    document.addEventListener('click', handleModalClose);

    return () => {
        document.removeEventListener('click', handleModalClose);
    };
}, []);

    return(
        <>
         <Helmet>
        <title>Digitize4life Portfolio | Custom Patches, Chenille Patches, Embroidery Digitizing, Vector
Conversions</title>
        <meta name="description" content="Explore our diverse portfolio showcasing exceptional work in custom patches, chenille
patches, embroidery digitizing, and vector conversions. View our gallery of high-quality images from projects
across more than 10 countries." />
        <meta name="keywords" content="Custom patches, Chenille patches, Embroidery digitizing, Vector conversions, Velcro patches,
Digitize4life portfolio, Patches showcase, Embroidery design, Vector artwork, Creative stitching."/>
 <link rel="canonical" href="https://digitize4life.com/portfolio"></link>
     </Helmet>
        <Banner title="Portfolio" metatitle="Diverse Embroidery Digitizing Designs Portfolio | Digitize4Life" metades="Explore our extensive portfolio showcasing a diverse range of meticulously crafted
embroidery designs. Discover the artistry behind each project and envision the possibilities for your custom
embroidery needs.
" image="images/banner.jpg"/>
      {/* Project Area start */}
<section className="project-page-area rel z-1 py-60">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-9">
        <div className="section-title text-center mb-40 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">OUR PORTFOLIO</span>
          <h2>Elevating Creativity, One Stitch at a Time</h2>
          <p>Step into the world of Digitize4life and witness the culmination of creativity and craftsmanship in
our diverse portfolio. Over the past years, we've had the privilege of working on projects spanning more than
10 countries, delivering high-quality solutions in custom patches, chenille patches, embroidery digitizing, and
vector conversions.
</p>
<p>Explore our gallery below to discover the artistry in each stitch and the transformative power of vector
conversions. From intricate embroidery designs to vibrant custom patches, our portfolio reflects our
commitment to excellence.
</p>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid">
    <ul className="project-filter mb-50 wow fadeInUp delay-0-4s">
      <li className="current" onClick={() => setCategory('All')}>Show All</li>
      {portfoliodata && [...new Set(portfoliodata.map(item => item.name))].map((name, index) => (
    <li className="current" key={index} onClick={() => setCategory(name)}>
        {name}
    </li>
))}

    </ul>
    <div className="row project-two-active project-twoheight">
      {filteredPortfolio && filteredPortfolio.slice(0, visibleImages).map(({id,name,image})=>{
        return(
          <div className="col-xl-3 col-lg-4 col-sm-6 item logoo 3dprint" key={id+name} onClickCapture={onOpenModal} onClick={()=>setLargePic(image)}>
          <div className="project-item-two">
            <img src={image} alt={name} />
          </div>
        </div>
        )
      })}

    </div>
    {filteredPortfolio && filteredPortfolio.length > visibleImages && ( // Render the "Load More" button conditionally
        <div className="text-center mt-10">
          <button onClick={handleLoadMore} className="theme-btn">Load More</button>
        </div>
      )}
  </div>
</section>
{largePic && <Modal open={open} onClose={onCloseModal} center>
          <div className="largerpicmodelcontainer">
          <img src={largePic} alt={largePic} />
          </div>
      </Modal>

}
{/* Project Area end     <div className="col-xl-3 col-lg-4 col-sm-6 item tshirt branding">
        <div className="project-item-two">
          <img src="images/projects/project10.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">Banner Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="images/projects/project10.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
      </div> {largePic && <div className="modalConationer">
  <span className="close-btn" onClick={() => setLargePic(null)}>×</span>
            <img src={largePic} alt={largePic} />
        </div>} */}

        <CTAArea/>
        </>
    )
}