import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

export const Blog=()=>{
   const [blogs,setBlogs]=useState();
  const GetBlog=async(url)=>{
    try{
     const resblog=await axios.get(url)
     setBlogs(resblog.data.data)
    }catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    GetBlog('https://shop.digitize4life.com/api/v2/blogs');
  },[])


    return(
       <section className="blog-area z-1 rel bgc-lighter pt-100 pb-100">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-6 col-lg-7">
        <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">Latest News &amp; Blog</span>
          <h2>Stay Updated with Our Latest News and Insights</h2>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      {blogs && blogs.slice(0, 3).map(({banner,id,category_name,slug,title,formatted_created_at})=>{
       const formattedCategoryName = category_name.replace(/\s+/g, '-');
       return(
<div className="col-xl-4 col-md-6" key={id}>
        <div className="blog-item wow fadeInUp delay-0-2s">
          <div className="image">
            <img src={banner} alt="Blog" />
          </div>
          <div className="content">
            <div className="author">
              <Link className="name" to={`/blogs/${formattedCategoryName}`}>{category_name}</Link>
            </div>
            <span className="date"><i className="far fa-calendar-alt" /> <Link  to="/blogs">{formatted_created_at}</Link></span>
            <h4><Link to={`/blog/${slug}`}>{title}</Link></h4>
            <Link to={`/blog/${slug}`} className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
          </div>
        </div>
      </div>
        )
      })}
    </div>
  </div>
  <div className="circle-shapes">
    <div className="shape-inner">
      <span className="dot-one" />
      <span className="dot-two" />
      <span className="dot-three" />
      <span className="dot-four" />
      <span className="dot-five" />
    </div>
  </div>
</section>

    )
}