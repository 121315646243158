import { HeroSession } from "../Hero/HeroSession"
import { About } from "../Landing/About"
import { Blog } from "../Landing/Blog"
import { CTAArea } from "../Landing/CTAArea"
import { Counter } from "../Landing/Counter"
import { Pricing } from "../Landing/Pricing"
import { Profile } from "../Landing/Profile"
import { Project } from "../Landing/Project"
import { Services } from "../Landing/Services"
import { Testimonials } from "../Landing/Testimonials"
import {Helmet} from "react-helmet";

export const Home=()=>{
    return(
        <>
        <Helmet>
        <title>Expert Embroidery Digitizing & Vector Conversion Services | Custom Patches</title>
        <meta name="description" content="Transform your ideas into reality with our top-notch embroidery digitizing and vector
conversion services. Explore our portfolio for stunning custom patches that reflect your unique vision!
" />
        <meta name="keywords" content="Embroidery digitizing, vector conversion services, custom patches, professional digitizers,
bespoke embroidery, quality vector graphics."/>
 <link rel="canonical" href="https://digitize4life.com"></link>
     </Helmet>
         <HeroSession/>
   <Profile/>
   <About/>
   <Services/>
   <Counter/>
   <Project/>
   <CTAArea/>
   <Testimonials/>
   <Pricing/>
   <Blog/></>
    )
}