export const Searchpopup=()=>{
    return(
        <>
         {/*<!-- search start-->*/}
       <div className="td-search-popup" id="td-search-popup">
  <form action="https://webtend.site/html/printco/index.html" className="search-form">
    <div className="form-group">
      <input type="text" className="form-control" placeholder="Search....." required />
    </div>
    <button type="submit" className="submit-btn"><i className="fa fa-search" /></button>
  </form>
</div>
 {/*<!-- search popup end-->*/}
 <div className="body-overlay" id="body-overlay"></div>
 {/*<!--Form Back Drop--> */}
 <div className="form-back-drop"></div>
 </>
    )
}