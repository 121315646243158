import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"

export const TeamPage=()=>{
    return(
     <>
     <Banner title="Amazing Team" image="images/banner.jpg"/>
    <div>
  {/* Why Choose Area start */}
  <section className="team-page-why-choose pt-120 pb-130 rel z-1">
    <div className="container">
      <div className="row large-gap align-items-center justify-content-between">
        <div className="col-lg-6">
          <div className="why-choose-services-content rmb-65 wow fadeInUp delay-0-2s">
            <div className="section-title mb-20">
              <span className="sub-title mb-15">Why Choose Us</span>
              <h2>We’re USA Based Printing Services Company</h2>
            </div>
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatec accusantium doloremque laudantium totam aperiam eaquey quae abillo inventore veritatis quasi architecto</p>
            <div className="skillbar-wrap mt-35">
              <div className="skillbar" data-percent={83}>
                <span className="skillbar-title">Printing Services</span>
                <div className="skillbar-bar"><span className="skill-bar-percent" /></div>
              </div>
              <div className="skillbar" data-percent={94}>
                <span className="skillbar-title">Creativity</span>
                <div className="skillbar-bar"><span className="skill-bar-percent" /></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="team-why-choose-images wow fadeInLeft delay-0-2s">
            <img className="image-one wow fadeInRight delay-0-6s" src="assets/images/team/why-choose2.jpg" alt="Why Choose" />
            <img className="image-two wow fadeInDown delay-0-4s" src="assets/images/team/why-choose1.jpg" alt="Why Chooose" />
            <div className="circle-shapes">
              <div className="shape-inner">
                <span className="dot-one" />
                <span className="dot-two" />
                <span className="dot-three" />
                <span className="dot-four" />
                <span className="dot-five" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Why Choose Area end */}
  {/* Team page Counter Area start */}
  <div className="team-page-counter">
    <div className="container container-1350">
      <div className="faq-counter text-white bgc-black">
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="counter-item style-two wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="far fa-plus" />
              </div>
              <p>Active User on Product We Created</p>
              <span className="count-text k-plus" data-speed={3000} data-stop={58}>0</span>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="counter-item style-two wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="far fa-plus" />
              </div>
              <p>We Have Done lot’s of Printing Projects</p>
              <span className="count-text plus" data-speed={3000} data-stop={3654}>0</span>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="counter-item style-two wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="far fa-plus" />
              </div>
              <p>Project We Completed Along the Way</p>
              <span className="count-text plus" data-speed={3000} data-stop={8630}>0</span>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="counter-item style-two wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="far fa-plus" />
              </div>
              <p>We Have Many Years Of Experience</p>
              <span className="count-text plus" data-speed={3000} data-stop={24}>0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team page Counter Area end */}
  {/* Team Area start */}
  <section className="team-page-area rel z-1 pt-120 pb-130">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-9">
          <div className="section-title text-center mb-50 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">Our Team Member</span>
            <h2>We Have Experience Team Member Meet With Us</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-2s">
            <div className="image">
              <img src="assets/images/team/member1.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Michael S. Harper</h5>
              <span className="designation">CEO &amp; Founder</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-4s">
            <div className="image">
              <img src="assets/images/team/member2.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Jerome M. Burns</h5>
              <span className="designation">Senior Manager</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-6s">
            <div className="image">
              <img src="assets/images/team/member3.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Thomas B. Gutierrez</h5>
              <span className="designation">Web Developer</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-2s">
            <div className="image">
              <img src="assets/images/team/member4.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Kevin M. Dickens</h5>
              <span className="designation">Senior Manager</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-4s">
            <div className="image">
              <img src="assets/images/team/member5.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Caleb K. Griswold</h5>
              <span className="designation">Junior Designer</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-6s">
            <div className="image">
              <img src="assets/images/team/member6.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Johnathan C. Grigsby</h5>
              <span className="designation">Business Consultant</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-2s">
            <div className="image">
              <img src="assets/images/team/member7.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Lawrence C. Reyes</h5>
              <span className="designation">Web Developer</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-4s">
            <div className="image">
              <img src="assets/images/team/member8.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Donald R. Chapman</h5>
              <span className="designation">CEO &amp; Founder</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="team-member wow fadeInUp delay-0-6s">
            <div className="image">
              <img src="assets/images/team/member9.jpg" alt="Member" />
            </div>
            <div className="content">
              <h5>Harold K. Mendoza</h5>
              <span className="designation">Marketing Officer</span>
              <div className="social-style-two">
                <a href="#"><i className="fab fa-facebook-f" /></a>
                <a href="#"><i className="fab fa-twitter" /></a>
                <a href="#"><i className="fab fa-dribbble" /></a>
                <a href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 text-center">
          <a href="team.html" className="theme-btn style-three">View More Us <i className="far fa-long-arrow-right" /></a>
        </div>
      </div>
    </div>
    <div className="circle-shapes">
      <div className="shape-inner">
        <span className="dot-one" />
        <span className="dot-two" />
        <span className="dot-three" />
        <span className="dot-four" />
        <span className="dot-five" />
      </div>
    </div>
  </section>
  {/* Team Area end */}
</div>

     <CTAArea/>
     </>
    )
}