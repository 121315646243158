import { useRef, useState } from "react";
import { Link } from "react-router-dom"
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export const About=()=>{
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

    const togglePlay = () => {
        const video = document.getElementById('about-videohome');
        if (video && video.src) {
          if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
      } else {
          console.error('Video element or src attribute is undefined.');
      }
        
    };

     // Function to handle 'ended' event
     const handleVideoEnded = () => {
      setIsPlaying(false); // Update state when the video ends
  };
    return(
     <div className="about-area py-40 rel z-1">
  <div className="container">
    <div className="row align-items-center justify-content-between">
      <div className="col-lg-6">
        <div className="about-images rmb-65">
          <div className="row align-items-end justify-content-center">
            <div className="col-sm-9 col-11">
              <div className="about-video wow fadeInRight delay-0-6s">
                <video ref={videoRef} id="about-videohome" src="images/Home/3rd sec video.mov" alt=" Experience reliable support round the clock. We are here to assist you at every step of your
embroidery and design journey." title="24/7 Customer Support" style={{width:'100%',height:'100%',borderRadius:'25px'}} onClick={togglePlay} onEnded={handleVideoEnded}></video>
                 {!isPlaying && (
                <div className="video-play1" onClick={togglePlay}>
                    <i className="fas fa-play"></i>
                </div>
            )}
              </div>
            </div>
            <div className="col-6">
              <div className="image wow fadeInLeft delay-0-4s">
              <img src="images/Home/3rd sec top right.jpg" alt="Our commitment to quality is unmatched. Explore our work and witness the attention to detail in
every stitch and pixel.
" title="Quality Assurance" style={{borderRadius:"25px"}}/>
              </div>
            </div>
            <div className="col-6">
              <div className="image wow fadeInDown delay-0-2s">
              <img src="images/Home/3rd sec top left.png" alt="Witness the creative journey! Our design process ensures that your vision is translated
seamlessly into custom patches and graphics." title="Creative Designing Process" style={{borderRadius:"25px"}}/>
              </div>
            </div>
          </div>
          <div className="circle-shapes">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="about-content wow fadeInUp delay-0-2s">
          <div className="section-title mb-20">
            <span className="sub-title mb-15">About Comapny</span>
            <h2>We’re USA Based Embroidery Digitizing Services Company</h2>
          </div>
          <div className="experience-year mb-20">
            <h6>We Have More than</h6>
            <span className="number">12</span>
            <h6>Years Of Experience in Printing Services</h6>
          </div>
          <p> At Digitize4life, our primary goal is to deliver embroidery digitizing services that go beyond the
ordinary. We are not just a service provider; we are architects of your artistic vision. Our commitment is to
bring out the best for our customers, establishing long-term relationships based on trust and quality</p>
          <div className="about-btns pt-5">
            <Link to="/about" className="theme-btn mt-20">Learn More <i className="far fa-long-arrow-right" /></Link>
            <button className="about-video-play1 mt-20" onClick={onOpenModal} style={{backgroundColor:"transparent"}}><i className="fas fa-play" /> <span>How IT Works</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal open={open} onClose={onCloseModal} center>
  <video id="about-videohome" src="images/Home/3rd sec video.mov" alt=" Experience reliable support round the clock. We are here to assist you at every step of your
embroidery and design journey." title="24/7 Customer Support" style={{width:'100%',height:'100%'}}  autoPlay controls
></video>
      </Modal>
</div>

    )
}