import { Link, useParams,useNavigate } from "react-router-dom"
import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"
import { useEffect, useState } from "react"
import axios from "axios"
import {Helmet} from "react-helmet";

export const Blog=()=>{
  const [blogs,setBlogs]=useState();
  const category=useParams();
  const navigate = useNavigate();
  const formattedCategoryName = category.category ? category.category.replace(/-/g, ' ') : 'All';

  const [selectedCategory, setSelectedCategory] = useState(formattedCategoryName);
  const [uniqueCategories, setUniqueCategories] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');

  const handleCategoryClick = (categoryName) => {
    const categoryPath = categoryName === 'All' ? '' : categoryName.replace(/ /g, '-');
    navigate(`/blogs/${categoryPath}`);
  };

  const GetBlog=async(url)=>{
    try{
     const resblog=await axios.get(url)
     setBlogs(resblog.data.data)
    }catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    GetBlog('https://shop.digitize4life.com/api/v2/blogs');
  },[])


  useEffect(() => {
    if (blogs && blogs.length > 0) {
      const categories = new Set(blogs.map(blog => blog.category.category_name));
      setUniqueCategories(categories);
    }
  }, [blogs]);

  useEffect(() => {
    setSelectedCategory(formattedCategoryName);
  }, [formattedCategoryName]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };





    return(
        <>
            <Helmet>
              <title>Blogs {formattedCategoryName && formattedCategoryName} Digitize4life</title>
              {formattedCategoryName!=="All"?<link rel="canonical" href={`https://digitize4life.com/blogs/${formattedCategoryName}`}></link>:<link rel="canonical" href="https://digitize4life.com/blogs"></link>}
            </Helmet>
        <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/Aboutispagecover.png)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000'}}>Blogs</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        {category.category?<li className="breadcrumb-item"><Link to="/blogs">Blogs</Link></li>:<li className="breadcrumb-item active">Blogs</li>}
        {category.category && <li className="breadcrumb-item active">{formattedCategoryName}</li>}
      </ol>
    </nav>
  </div>
</div>
</section>
       {/* Blog Page Area start */}
<section className="blog-standard-area py-60">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-xl-4 col-lg-5 order-lg-1 order-2">
      <div className="blog-sidebar rmb-75">
          <div className="widget widget-search wow fadeInUp delay-0-2s">
            <h3 className="widget-title">Search</h3>
            <form className="default-search-form" onSubmit={(e)=>e.preventDefault()}>
              <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearchChange}  required />
              <button type="submit" className="searchbutton far fa-search" />
            </form>
          </div>
          <div className="widget widget-category wow fadeInUp delay-0-4s">
            <h4 className="widget-title">Category</h4>
            <ul>
              <li><button className={selectedCategory==='All'?'colorbluew':''} onClick={() => handleCategoryClick('All')} style={{background:"transparent"}}>
               ALL
              </button></li>
            {[...uniqueCategories].map((categoryName, index) => (
            <li key={index}>
              <button className={selectedCategory===categoryName?'colorbluew':''} onClick={() => handleCategoryClick(categoryName)} style={{background:"transparent"}}>
                {categoryName}
              </button>
            </li>
          ))}
            </ul>
          </div>
          <div className="widget widget-recent-post wow fadeInUp delay-0-2s">
            <h4 className="widget-title">Recent Blogs</h4>
            <ul>
            {blogs && blogs.slice(0,3).map(({banner,id,category_name,slug,title})=>{
                return(
                  <li key={id+category_name}>
                  <div className="image">
                    <img src={banner} alt="Post" />
                  </div>
                  <div className="content">
                    <h6><Link to={`/blog/${slug}`}>{title}</Link></h6>
                  </div>
                </li>
                )
              })}
            </ul>
          </div>
        
        </div>
      </div>
      <div className="col-lg-7 order-lg-2 order-1  blog-standardhigh">
        {selectedCategory === 'All' ? ( <div className="blog-standard-wrap">
        {blogs && blogs.filter(blog => {
        return (!searchQuery || blog.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }).map(({banner,id,category_name,slug,short_description,formatted_created_at,title})=>{
      const formattedCategoryName = category_name.replace(/\s+/g, '-');
            return(
              <div className="blog-standard-item wow fadeInUp delay-0-2s" key={id+title}>
              <div className="image">
                <img src={banner} alt="Blog Standard" style={{height:'22rem'}}/>
              </div>
              <div className="content">
                <ul className="blog-meta">
                  <li>
                    <div className="author">
                      <Link to={`/blogs/${formattedCategoryName}`} className="name">{category_name}</Link>
                    </div>
                  </li>
                  <li><i className="far fa-calendar-alt" />{formatted_created_at}</li>
                </ul>
                <h4><Link to={`/blog/${slug}`}>{title}</Link></h4>
                <p>{short_description}</p>
                <Link to={`/blog/${slug}`} className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
              </div>
            </div>
            )
          })}
        </div>):(<div className="blog-standard-wrap">
        {blogs && blogs.filter(blog => {
        return (!selectedCategory || blog.category_name === selectedCategory) &&
            (!searchQuery || blog.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }).map(({banner,id,category_name,slug,short_description,formatted_created_at,title})=>{
      const formattedCategoryName = category_name.replace(/\s+/g, '-');
            return(
              <div className="blog-standard-item wow fadeInUp delay-0-2s" key={id+title}>
              <div className="image">
                <img src={banner} alt="Blog Standard" style={{height:'22rem'}}/>
              </div>
              <div className="content">
                <ul className="blog-meta">
                  <li>
                    <div className="author">
                      <Link to={`/blogs/${formattedCategoryName}`} className="name">{category_name}</Link>
                    </div>
                  </li>
                  <li><i className="far fa-calendar-alt" />{formatted_created_at}</li>
                </ul>
                <h4><Link to={`/blog/${slug}`}>{title}</Link></h4>
                <p>{short_description}</p>
                <Link to={`/blog/${slug}`} className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
              </div>
            </div>
            )
          })}
        </div>)
        }

   
         
        
        {/*  <ul className="pagination flex-wrap pt-20">
            <li className="page-item disabled">
              <span className="page-link"><i className="fas fa-chevron-left" /></span>
            </li>
            <li className="page-item active">
              <span className="page-lin                <span className="sr-only">(current)</span>
              </span>
            </li>
            <li className="page-item"><a className="page-link" href="#">02</a></li>
            <li className="page-item"><a className="page-link" href="#">03</a></li>
            <li className="page-item"><a className="page-link" href="#">04</a></li>
            <li className="page-item">
              <a className="page-link" href="#"><i className="fas fa-chevron-right" /></a>
            </li>
          </ul> */}
      </div>
    </div>
  </div>
</section>
{/* Blog Page Area end */}

        <CTAArea/>
        </>
    )
}