import axios from "axios"
import { useState } from "react"
import { FaTiktok, FaXTwitter, FaYoutube } from "react-icons/fa6"
import { Link } from "react-router-dom"

const Sidebar=()=>{
  const [name,setname]=useState("")
  const [email,setemail]=useState("")
  const [phone,setphone]=useState("")
  const [subject,setsubject]=useState('Select a service')
  const [message,setmessage]=useState("")

  const ContactMe=async(e)=>{
    e.preventDefault();
    try{
      const res=await axios.post('https://shop.digitize4life.com/api/v2/contact/post',{name,email,phone,subject,message},{
        headers:{
          Accept: 'application/json'
        }
      })
      console.log('name',name,'email',email,'phone',phone,'subject',subject,'message',message,'res',res.data)
      if(res.data.status==='success'){
        alert(res.data.message)
        setphone("")
        setemail("")
        setsubject('Select a service')
        setname("")
        setmessage("")
      }
    }catch(err){
      console.log(err);
    }
  }

    return(
       <section className="hidden-bar">
  <div className="inner-box text-center">
    <div className="cross-icon"><span className="fa fa-times" /></div>
    <div className="title">
      <h4>Get Appointment</h4>
    </div>
    {/*Appointment Form*/}
    <div className="appointment-form">
      <form onSubmit={ContactMe}>
        <div className="form-group">
          <input type="text" name="text" placeholder="Full Name" value={name} onChange={(e)=>setname(e.target.value)}  required />
        </div>
        <div className="form-group">
          <input type="email" name="email" placeholder="Email Address" value={email} onChange={(e)=>setemail(e.target.value)} required />
        </div>
        <div className="form-group">
          <input type="text" name="phone" value={phone} onChange={(e)=>setphone(e.target.value)}  placeholder="Phone Number" required />
        </div>
        <div className="form-group">
        <select value={subject} onChange={(e)=>setsubject(e.target.value)} id="sideselect" style={{paddingLeft:"25px"}}>
                    <option value="Select a service">Select a service</option>
                    <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                    <option value="Vector Conversion">Vector Conversion</option>
                    <option value="Custom Patches">Custom Patches</option>
                  </select>
        </div>
        <div className="form-group">
          <textarea placeholder="Message" value={message} onChange={(e)=>setmessage(e.target.value)} rows={5} />
        </div>
        <div className="form-group">
          <button type="submit" className="theme-btn">Submit now</button>
        </div>
      </form>
    </div>
    {/*Social Icons*/}
    <div className="social-style-one">
      <Link to="https://twitter.com/Digitize4lif" target="_blank"><FaXTwitter /></Link>
      <Link to="https://www.facebook.com/digitize4lifellc" target="_blank"><i className="fab fa-facebook-f" /></Link>
      <Link to="https://www.instagram.com/digitize4lifellc/" target="_blank"><i className="fab fa-instagram" /></Link>
      <Link to="https://www.pinterest.com/digitize4lifellc" target="_blank"><i className="fab fa-pinterest-p" /></Link>
      <Link to="https://www.alignable.com/bedford-tx/digitize4life" target="_blank"><i className="fa fa-font" /></Link>
      <Link to="https://www.twitch.tv/digitize4life" target="_blank"><i className="fab fa-twitch" /></Link>
      <Link to="https://www.tiktok.com/@digitize4lifellc" target="_blank"><FaTiktok/></Link>
      <Link to="https://www.youtube.com/@digitize4life_official" target="_blank"><FaYoutube/></Link>
      <Link to="https://www.linkedin.com/company/digitize4lifellc" target="_blank"><i className="fab fa-linkedin-in" /></Link>
    </div>
  </div>
</section>

    )
}
export default Sidebar;