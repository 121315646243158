import { Link } from "react-router-dom"
import { Banner } from "../Hero/Banner"
import { CTAArea } from "../Landing/CTAArea"

export const Portfoliodetail=()=>{
    return(
     <div>
        <Banner title="Portfolio detail"/>
  {/* Project Details Area start */}
  <section className="project-details-area pt-130 rpt-120">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7">
          <div className="content mb-75 wow fadeInUp delay-0-2s">
            <div className="section-title"><h2>T-Shirt Printing</h2></div>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas mole stias excepturi sint occaecati cupiditate non provident, similique sunn culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga harums quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo </p>
            <ul className="list-style-one pt-5">
              <li>Quality Services Provider</li>
              <li>Printing, Designing and Transportation</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="project-description wow fadeInDown delay-0-2s">
            <ul>
              <li>
                <span>Project Category</span>
                <h3>T-Shirt Printing</h3>
              </li>
              <li>
                <span>Company Name</span>
                <h3>ABCD IT Ltd, USA</h3>
              </li>
              <li>
                <span>Project Date</span>
                <h3>Jule 28, 2022</h3>
              </li>
              <li>
                <span>WEBSITE</span>
                <h3>www.printco.net</h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="image mb-40 wow fadeInUp delay-0-2s">
        <img src="assets/images/projects/project-details.jpg" alt="Project Details" />
      </div>
      <div className="content mb-75">
        <div className="section-title mb-20"><h2>The Challenge</h2></div>
        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quialuptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porroy quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="image mb-30 wow fadeInUp delay-0-2s">
            <img src="assets/images/projects/project-middle1.jpg" alt="Project Middle" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="image mb-30 wow fadeInUp delay-0-4s">
            <img src="assets/images/projects/project-middle2.jpg" alt="Project Middle" />
          </div>
        </div>
      </div>
      <div className="content mt-10 mb-75 wow fadeInUp delay-0-2s">
        <div className="section-title mb-20"><h2>Project Summery</h2></div>
        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quialuptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porroy quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
      </div>
    </div>
  </section>
  {/* Project Details Area End */}
  {/* Next Prev Post Area start */}
  <section className="next-prev-post-area">
    <div className="container">
      <hr />
      <div className="next-prev-post py-65">
        <div className="prev-post wow fadeInLeft delay-0-2s">
          <div className="image">
            <img src="assets/images/blog/prev-post.jpg" alt="News" />
          </div>
          <div className="content">
            <h5><Link to="blog-details">Digital Printing</Link></h5>
            <Link to="/blog-details" className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
          </div>
        </div>
        <Link className="show-all" to="/blog">
          <i className="far fa-ellipsis-h" />
          <i className="far fa-ellipsis-h" />
          <i className="far fa-ellipsis-h" />
        </Link>
        <div className="next-post wow fadeInRight delay-0-2s">
          <div className="content">
            <h5><Link to="/blog-details">Ofset Printing</Link></h5>
            <Link to="/blog-details" className="read-more">Read More <i className="far fa-long-arrow-right" /></Link>
          </div>
          <div className="image">
            <img src="assets/images/blog/next-post.jpg" alt="News" />
          </div>
        </div>
      </div>
      <hr />
    </div>
  </section>
  {/* Next Prev Post Area end */}
  {/* Related Project Area start */}
  <section className="related-project rel z-1 pt-60 pb-40">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-9">
          <div className="section-title text-center mb-10 wow fadeInUp delay-0-2s">
            <h2>Related Projects</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="related-project-active">
        <div className="project-item-two">
          <img src="assets/images/projects/project2.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">T-Shirt Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project2.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
        <div className="project-item-two">
          <img src="assets/images/projects/project4.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">Box Design Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project4.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
        <div className="project-item-two">
          <img src="assets/images/projects/project7.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">Business Card Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project7.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
        <div className="project-item-two">
          <img src="assets/images/projects/project3.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">T-Shirt Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project3.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
        <div className="project-item-two">
          <img src="assets/images/projects/project5.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">Box Design Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project5.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
        <div className="project-item-two">
          <img src="assets/images/projects/project6.jpg" alt="Project" />
          <div className="project-content">
            <div className="left-part">
              <h5><Link to="/project-details">Business Card Printing</Link></h5>
              <span>Design, Printing</span>
            </div>
            <a href="assets/images/projects/project6.jpg" className="plus"><i className="fal fa-plus" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Related Project Area end */}
  <CTAArea/>
</div>

    )
}