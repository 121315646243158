import { Link } from "react-router-dom"

export const CTAArea=()=>{
    return(  
    <section className="cta-area bgc-gradient">
    <div className="row">
      <div className="col-xl-4">
        <div className="cta-left-image rel z-1 wow fadeInLeft delay-0-4s">
          <img src="../images/Home/7th section left side.png" alt="CTA Left" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 align-self-center">
        <div className="cta-content text-white py-55 wow fadeInUp delay-0-2s">
          <div className="section-title mb-35">
            <span className="sub-title mb-10">Contact With Us</span>
            <h2>Ready with a design idea? Look no further!</h2>
          </div>
          <Link to="https://login.digitize4life.com/register.asp" className="theme-btn">Start Your Projects <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="cta-right-image rel z-1 wow fadeInRight delay-0-2s">
          <img src="../images/Home/7th section right side.png" alt="CTA Right" />
          <div className="circle-shapes white-shape no-animation">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>)
}