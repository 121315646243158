import {Helmet} from "react-helmet";
import { CTAArea } from "../Landing/CTAArea";
import { Link } from "react-router-dom";
export const RefundPage=()=>{
    return(
        <>
        <Helmet>
        <title>Digitize4Life - Transparent Refund Policy for Your Satisfaction</title>
        <meta name="description" content="Explore our customer-friendly refund policy at Digitize4Life. We offer a 7-day money-back
guarantee on services like embroidery digitizing and vector art conversion. Understand the nuances for
custom patches, ensuring a fair and satisfactory experience. Your satisfaction is our priority." />
        <meta name="keywords" content="Digitize4Life refund policy, Money-back guarantee, Custom patches refund, Embroidery digitizing
refund, Chenille patches, Transparent refund process."/>
<link rel="canonical" href="https://digitize4life.com/refund_policy"></link>
     </Helmet>

     <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/RefundCover.png)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000'}}>Refund Policy</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Refund Policy</li>
      </ol>
    </nav>
  </div>
</div>
</section>
     <section className="project-page-area rel z-1 pt-60 pb-40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8 col-lg-9">
        <div className="section-title text-center mb-40 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">REFUND POLICY</span>
          <h2>Ensuring Your Satisfaction</h2>
          <p>At Digitize4Life, we prioritize customer satisfaction, and our refund policy reflects our commitment to
providing a fair and transparent experience. Please review the details of our refund policy for different
services</p>
        </div>
        
      </div>
      <div className="section-title wow fadeInUp delay-0-2s">
        <h5>Services (Embroidery Digitizing & Vector Art Conversion):</h5>
        <p>We stand by the quality of our services and offer a 7-day money-back guarantee if the customer is
dissatisfied. To investigate and address concerns, we may request proof in the form of a sew-out or scanned
image of the final product. Your satisfaction is the cornerstone of our success.</p>
<h5>Custom Patches (Embroidered/Chenille):</h5>
        <p>For custom patches, our refund policy varies based on the stage of the order process:</p>
        <h5>No Refund:</h5>
        <p>No refund will be offered to customers requesting a single embroidery/Chenille patch for machine sew-out
verification. Costs incurred include embroidery digitizing, machine sew-out, and additional charges for
shipping.</p>
        <p><span className="sub-title">Note: </span> For Leather/PVC/Printed/Woven Patches, no refund is offered after production approval from customers</p>
        <h5>Partial Refund:</h5>
        <p>A partial refund will be provided to customers who request multiple patches for machine sew-out verification,
approve the sample, but cancel the order before production. Costs incurred include embroidery digitizing,
machine sew-out, and additional charges for shipping.
</p>
<h5>Full Refund:</h5>
<p>A full refund will be offered to customers who request a single or multiple patches for machine sew-out
verification and cancel the order before sample approval. Costs incurred include embroidery digitizing,
machine sew-out, and additional charges for shipping.
</p>
<p><span className="sub-title">Note: </span> For Leather/PVC/Printed/Woven Patches, a full refund is offered before customer production approval.
</p>
<p>At Digitize4Life, we believe in transparent and fair business practices. If you have any questions or require
further clarification about our refund policy, please feel free to contact us. Your satisfaction is our priority, and
we are committed to making your experience with us exceptional.</p>
        </div>
     </div>
     </div>
     </section>
     <CTAArea/>
     </>
    )
}