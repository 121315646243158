import { Link } from "react-router-dom";
import Slider from "react-slick";

export const Profile=()=>{
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
    return(
        <section className="what-we-provide bgc-lighter pt-45 pb-45" style={{backgroundImage: 'url(images/background/what-we-provide.png)'}}>
  <div className="container">
    <div className="section-title text-center mb-20 wow fadeInUp delay-0-2s">
      <span className="sub-title mb-10">What We Provide</span>
      <h2>Discover a World of Possibilities in Digitizing</h2>
    </div>
    <Slider {...settings} className="newslick">
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-2s">
        <img src="images/Home/service boxes icons for home and services page/embroidery-digitizing.gif" alt="Embroidery Digitizing"/>
        <h6><Link to="/services/embroidery-digitizing">Embroidery Digitizing</Link></h6>
      </div>
      </div>
      <div className="p-3">
         <div className="what-we-provide-item wow fadeInUp delay-0-3s">
      <img src="images/Home/service boxes icons for home and services page/vector-conversion.gif" alt="Vector Conversion"/>
        <h6><Link to="/services/vector-conversion">Vector Conversion</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-4s">
      <img src="images/Home/service boxes icons for home and services page/custom-patch.gif" alt="Custom Patches"/>
        <h6><Link to="/custom-patches">Custom Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-5s">
      <img src="images/Home/service boxes icons for home and services page/embroidery-digitizing.gif" alt="Flat Embroidery Digitizing"/>
        <h6><Link to="/services/flat-embroidery-digitizing">Flat Embroidery Digitizing</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-6s">
      <img src="images/Home/service boxes icons for home and services page/graphic-designing.gif" alt="Graphic Designing"/>
        <h6><Link to="/services/graphic-designing">Graphic Designing</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-7s">
      <img src="images/Home/service boxes icons for home and services page/applique.gif" alt="Applique Embroidery Digitizing" className="m-auto" style={{width:"4rem",height:"4rem"}}/>
        <h6 className="mt-3 mb-4"><Link to="/services/applique embroidery digitizing">Applique Embroidery Digitizing</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/line-art.gif" alt="Line Art"/>
        <h6><Link to="/services/line-art">Line Art</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/3D-Puff.gif" alt="3D Puff Embroidery Digitizing"/>
        <h6><Link to="/services/3D-puff-embroidery-digitizing">3D Puff Embroidery Digitizing</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/Embroidered-Patches.gif" alt="Embroidered Patches"/>
        <h6><Link to="/custom-patches/embroidered-patches">Embroidered Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/Chenille Patches.gif" alt="Chenille Patches"/>
        <h6><Link to="/custom-patches/chenille-patches">Chenille Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/Woven-patch.gif" alt="Woven Patches"/>
        <h6><Link to="/custom-patches/woven-patches">Woven Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/pvc.gif" alt="PVC Patches"/>
        <h6><Link to="/custom-patches/pvc-patches">PVC Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/Printed-patch.gif" alt="Printed Patches"/>
        <h6><Link to="/custom-patches/printed-patches">Printed Patches</Link></h6>
      </div>
      </div>
      <div className="p-3">
      <div className="what-we-provide-item wow fadeInUp delay-0-8s">
      <img src="images/Home/service boxes icons for home and services page/Leather-patch.gif" alt="Leather Patches"/>
        <h6><Link to="/custom-patches/leather-patches">Leather Patches</Link></h6>
      </div>
      </div>
    </Slider>
  </div>
</section>

    )
}