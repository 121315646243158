import { Link } from "react-router-dom"

export const Pricing=({para})=>{
    return(
      <section className="pricing-plan-area pt-60 pb-40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">Pricing Packages</span>
          <h2>Smart Embroidery Digitizing Pricing Plans</h2>
        </div>
      </div> 
    </div>
    <div className="row justify-content-center">
      <div className="col-xl-4 col-md-6">
        <div className="pricing-plan-item wow fadeInUp delay-0-2s">
          <h4 className="title">Vector Conversion</h4>
          <div className="image">
            <img src="images/Home/Price cards/Vector Conversion.png" alt=" Unlock the power of precision with our Vector Conversion services. View our competitive rates to elevate
your graphics and designs to a whole new level" title="Vector Conversion Rates"/>
          </div>
          <ul>
            <li>Simple vector - $15</li>
            <li>Medium vector - $20</li>
            <li>Complex vector - $60</li>
            <li>Free Color Separation<i className="fas fa-check" /></li>
            <li>Free Shipping<i className="fas fa-check" /></li>
            <li>Free Samples<i className="fas fa-check" /></li>
          </ul>
          {/*<span className="price">258.53</span> */}
          <Link to="/contact" className="theme-btn style-three">Request Quote <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>
      <div className="col-xl-4 col-md-6">
        <div className="pricing-plan-item wow fadeInUp delay-0-4s">
          <h4 className="title">Embroidery Digitizing</h4>
          <div className="image">
            <img src="images/Home/Price cards/Embroidery Digitizing.png" alt="Discover cost-effective solutions with our tailored pricing for flawless Embroidery Digitizing. Unleash the
beauty of intricate designs without breaking the bank.
" title="Embroidery Digitizing Pricing"/>
          </div>
          <ul>
            <li>Text Name (LC / Cap) - $10</li>
            <li>LC / Cap Design (under 10k stitches) - $15</li>
            <li>Medium Size Design - $20</li>
            <li>Jacket Front (under 40k stitches) Min - $30</li>
            <li>Jacket (up to 100k stitches) - $75</li>
            <li><select>
                 <option>Select Thread</option>
                 <option>Staple</option>
                 <option>Polyester</option>
                 <option>Chenille</option>
              </select></li>
            <li>Free Shipping<i className="fas fa-check" /></li>
            <li>Free Samples<i className="fas fa-check" /></li>
          </ul>
          <Link to="/contact" className="theme-btn style-three">Request Quote <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>
      <div className="col-xl-4 col-md-6">
        <div className="pricing-plan-item wow fadeInUp delay-0-6s">
          <h4 className="title">Custom Patches</h4>
          <div className="image">
            <img src="images/Home/Price cards/Custom Patches.png" alt=" Explore the affordability of personalized style. Check out our transparent pricing for high-quality Custom
Patches tailored to your specifications" title="Custom Patches Cost"/>
          </div>
          <ul>
          <li><select onClick={(e)=>localStorage.setItem('services',e.target.value)} required>
                 <option>Select Type</option>
                 <option value="Embroidery Digitizing">Embroidered</option>
                 <option value="Custom Patches">Chenille</option>
                 <option value="Custom Patches">Woven</option>
                 <option value="Custom Patches">PVC</option>
                 <option value="Custom Patches">Printed</option>
                 <option value="Custom Patches">Leather</option>
                 <option value="Vector Conversion">Combined</option>
              </select></li>
              <li><select>
                 <option>Select Fabric</option>
                 <option>Polyester</option>
                 <option>Nylon</option>
                 <option>Felt</option>
              </select></li>
              <li><select>
                 <option>Select Thread</option>
                 <option>Simple thread</option>
                 <option>Multi thread</option>
                 <option>metallic thread</option>
              </select></li>
              <li><select>
                 <option>Select Backing</option>
                 <option>Iron On</option>
                 <option>Velcro</option>
                 <option>Sew-On</option>
                 <option>Adhesive</option>
                 <option>Plastic</option>
                 <option>No Backing</option>
              </select></li>
              <li><select>
                 <option>Select Border</option>
                 <option>Hot Cut Border</option>
                 <option>Merrowed Border</option>
                 <option>Satin Stitch Border</option>
              </select></li>
            <li>Additional Colors: 15¢<i className="fas fa-check" /></li>
            <li>Free Shipping<i className="fas fa-check" /></li>
            <li>Free Samples<i className="fas fa-check" /></li>
          </ul>
          <Link to="/contact" className="theme-btn style-three">Request Quote <i className="far fa-long-arrow-right" /></Link>
        </div>
      </div>

    </div>
    <div className="text-center">
      <p>{para}</p>
    <Link to="https://login.digitize4life.com/register.asp" className="theme-btn hover-two mt-20 mb-3">Get started<i className="far fa-long-arrow-right" /></Link>
    </div>
  </div>
</section>

    )
}