import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

export const Counter=()=>{
  const [isVisible, setIsVisible] = useState(false);
  const [counts, setCounts] = useState({
    activeUsers: 0,
    printingProjects: 0,
    completedProjects: 0,
    establishedYear: 0
  });

  const speed = 3000;
  const targetValues = {
    activeUsers: 40,
    printingProjects: 55,
    completedProjects: 100,
    establishedYear: 2012
  };

  const factCounterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (factCounterRef.current) {
      observer.observe(factCounterRef.current);
    }

    return () => {
      if (factCounterRef.current) {
        observer.unobserve(factCounterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setCounts(prevCounts => {
          const newCounts = { ...prevCounts };

          for (const key in newCounts) {
            if (key === 'establishedYear' && newCounts[key] < targetValues[key]) {
              // Increment the established year by 200 until it reaches the target value
              newCounts[key] += 200;
              if (newCounts[key] > targetValues[key]) {
                newCounts[key] = targetValues[key]; // Ensure the year stops at the target value
              }
            } else if (newCounts[key] < targetValues[key]) {
              // Increment other counts by 1 until they reach their target values
              newCounts[key]++;
            }
          }

          return newCounts;
        });
      }, 60);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

    return(
       <section className="fact-counter-area pt-40" ref={factCounterRef}>
        <Helmet>
        <script type="application/ld+json">
        {JSON.stringify(
      helmetJsonLdProp({ 
          "@context": "http://schema.org",
          "@type": "AggregateRating",
          "ratingValue": "5",
          "reviewCount": "218"
      })
    )}
    </script>
        </Helmet>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
          <span className="sub-title mb-10">Company Statistics</span>
          <h2>Fueling Success with Proven Numbers</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-3 col-md-6">
        <div className="counter-item wow fadeInUp delay-0-2s">
          <div className="icon">
            <i className="far fa-plus" />
          </div>
          <p>Active User on Products We Created</p>
          <span className="count-text k-plus" data-speed={speed} data-stop={targetValues.activeUsers}>{counts.activeUsers}</span>
          <p>Engaging and serving a vibrant community with our digitized creations.</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="counter-item wow fadeInUp delay-0-4s">
          <div className="icon">
            <i className="far fa-plus" />
          </div>
          <p>A Multitude of Printing Projects</p>
          <span className="count-text k-plus"data-speed={speed} data-stop={targetValues.printingProjects}>{counts.printingProjects}</span>
          <p>Embarking on numerous printing ventures, bringing ideas to life</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="counter-item wow fadeInUp delay-0-6s">
          <div className="icon">
            <i className="far fa-plus" />
          </div>
          <p>Projects Successfully Completed</p>
          <span className="count-text k-plus" data-speed={speed} data-stop={targetValues.completedProjects}>{counts.completedProjects}</span>
          <p>Demonstrating our dedication and efficiency in delivering quality outcomes.</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="counter-item wow fadeInUp delay-0-8s">
          <div className="icon">
            <i className="far fa-plus" />
          </div>
          <p>Become established in</p>
          <span className="count-text" data-speed={speed} data-stop={targetValues.establishedYear}>{counts.establishedYear}</span>
          <p>A legacy of expertise, evolving with each passing year.</p>
        </div>
      </div>
    </div>
    <div className="text-center">
    Join us in the realm of accomplishment, where every statistic represents a story of triumph and satisfaction.
    </div>
  </div>
</section>

    )
}