import { Link } from "react-router-dom"

export const Project=()=>{
    return(
 <section className="latest-project-area pt-40 pb-40">
  <div className="container">
    <div className="row justify-content-between align-items-center pb-45">
      <div className="col-lg-6">
        <div className="section-title mb-15 wow fadeInLeft delay-0-2s">
          <span className="sub-title mb-10">Recent Deliveries</span>
          <h2>Have a look at our recent Amazing Patches!</h2>
        </div>
      </div>
      <div className="col-lg-4 text-lg-end">
        <Link to="/portfolio" className="theme-btn mt-15 wow fadeInRight delay-0-2s">View More Projects <i className="far fa-long-arrow-right" /></Link>
      </div>
    </div>
    <div className="row align-items-center justify-content-between">
      <div className="col-lg-4">
        <div className="tab-btns-one rmb-75 nav flex-column nav-pills wow fadeInRight delay-0-2s">
          <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#project-tab-one">
          <img src="images/Home/6th Section Embroidered Patch.png" alt="Leather Patch" width={50} height={50}/>
            <span className="title mx-2">Embroidered Patch</span>
          </button>
          <button className="nav-link" data-bs-toggle="pill" data-bs-target="#project-tab-two">
          <img src="images/Home/6th Section Chenille Patch.png" alt="Leather Patch" width={50} height={50}/>
            <span className="title mx-2">Chenille Patch</span>
          </button>
          <button className="nav-link" data-bs-toggle="pill" data-bs-target="#project-tab-three">
            <img src="images/Home/6th Section Leather Patch.png" alt="Leather Patch" width={50} height={50}/>
            <span className="title mx-2">Leather Patch</span>
          </button>
          <button className="nav-link mb-0" data-bs-toggle="pill" data-bs-target="#project-tab-four">
          <img src="images/Home/6th Section Sequin Embroidery.png" alt="Leather Patch" width={50} height={50}/>
            <span className="title mx-2">Sequin Embroidery</span>
          </button>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="project-tab-content rel wow fadeInLeft delay-0-2s">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="project-tab-one">
            <div className="project-item">
                  <img src="images/Home/6th Section Embroidered Patch.png" alt="Project" style={{borderRadius:"25px"}}/>
                  <div className="content">
                    <div className="text">
                      <h4><Link to="/custom-patches/embroidered-patches">Embroidered Patch</Link></h4>
                      <span>Embroidered Patch</span>
                    </div>
                    <Link className="plus" to="/custom-patches/embroidered-patches">
                      <i className="far fa-plus" />
                    </Link>
                  </div>
                </div>
            </div>
            <div className="tab-pane fade" id="project-tab-two">
            <div className="project-item">
                  <img src="images/Home/6th Section Chenille Patch.png" alt="Project" style={{borderRadius:"25px"}}/>
                  <div className="content">
                    <div className="text">
                      <h4><Link to="/custom-patches/chenille-patches">Chenille Patch</Link></h4>
                      <span>Chenille Patch</span>
                    </div>
                    <Link className="plus" to="/custom-patches/chenille-patches">
                      <i className="far fa-plus" />
                    </Link>
                  </div>
                </div>
            </div>
            <div className="tab-pane fade" id="project-tab-three">
            <div className="project-item">
                  <img src="images/Home/6th Section Leather Patch.png" alt="Project" style={{borderRadius:"25px"}}/>
                  <div className="content">
                    <div className="text">
                      <h4><Link to="/custom-patches/leather-patches">Leather Patch</Link></h4>
                      <span>Leather Patch</span>
                    </div>
                    <Link className="plus" to="/custom-patches/leather-patches">
                      <i className="far fa-plus" />
                    </Link>
                  </div>
                </div>
            </div>
            <div className="tab-pane fade p-5" id="project-tab-four">
            <div className="project-item">
                  <img src="images/Home/6th Section Sequin Embroidery.png" alt="Project" style={{borderRadius:"25px"}}/>
                  <div className="content">
                    <div className="text">
                      <h4><Link to="/services/sequin-embroidery-digitizing">Sequin Embroidery</Link></h4>
                      <span>Sequin Embroidery</span>
                    </div>
                    <Link className="plus" to="/services/sequin-embroidery-digitizing">
                      <i className="far fa-plus" />
                    </Link>
                  </div>
                </div>
            </div>
          </div>
          <div className="circle-shapes">
            <div className="shape-inner">
              <span className="dot-one" />
              <span className="dot-two" />
              <span className="dot-three" />
              <span className="dot-four" />
              <span className="dot-five" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    )
}