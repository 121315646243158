import { Link } from "react-router-dom"

export const Sidebarmenu=()=>{
    return(
        <div className="sidebarmenuright-container">
          <Link to="/contact#contactForm"><button>
            Get Free Quote Now
          </button>
          </Link>
        </div>
    )
}

