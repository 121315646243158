import { Link } from "react-router-dom"

export const Services=()=>{
    return(
   <div className="services-area rel z-1">
  <div className="container-fluid">
    <div className="services-inner text-white bgc-black">
      <div className="row align-items-center">
        <div className="col-xl-4 col-lg-7">
          <div className="service-content rel p-100 py-40 rpb-100 wow fadeInUp delay-0-2s">
            <div className="section-title mb-30">
              <span className="sub-title mb-15">Top Embroidery Digitizing Company</span>
              <h2>Pixel Perfect Digitizing For Embroidery and Custom Patches</h2>
            </div>
            <h6>2024 Awards Winning Embroidery Digitizing Company</h6>
            <p>Transforming Designs into Masterpieces with Precision Embroidery Solutions</p>
            <Link to="/services" className="theme-btn hover-two mt-20">Learn More <i className="far fa-long-arrow-right" /></Link>
            <div className="circle-shapes no-animation">
              <div className="shape-inner">
                <span className="dot-one" />
                <span className="dot-two" />
                <span className="dot-three" />
                <span className="dot-four" />
                <span className="dot-five" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="row no-gap">
            <div className="col-md-6">
              <div className="service-item wow fadeInDown delay-0-4s">
                <div className="icon">
                  <i className="flaticon-print" />
                </div>
                <h3><Link to="/services/embroidery-digitizing">Embroidery Digitizing</Link></h3>
                <p>Unveiling the art of precision. Our embroidery digitizing services bring your designs to life with
flat embroidery, graphic designing, applique digitizing, line art, 3D puff embroidery, logo designing, chenille
digitizing, color separation, sequin digitizing, and photo editing.
</p>
                <Link to="/services/embroidery-digitizing" className="read-more">Explore <i className="far fa-long-arrow-right" /></Link>
                <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg.jpg)'}} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-item wow fadeInDown delay-0-4s">
                <div className="icon">
                  <i className="flaticon-3d-printer" />
                </div>
                <h3><Link to="/custom-patches">Custom Patches</Link></h3>
                <p>Crafting patches that tell your story. Our custom patches service offers a variety of options,
including embroidered patches, chenille patches, woven patches, PVC patches, printed patches, and leather
patches.
</p>
                <Link to="/custom-patches" className="read-more">Explore<i className="far fa-long-arrow-right" /></Link>
                <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg.jpg)'}} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-item wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon-print-1" />
                </div>
                <h3><Link to="/services/3D-puff-embroidery-digitizing">3D Puff Embroidery</Link></h3>
                <p>Step into the future with our 3D puff embroidery service. Immerse yourself in innovative designs
and unparalleled quality. Unleash creativity with precision and flair.</p>
                <Link to="/services/3D-puff-embroidery-digitizing" className="read-more">Explore <i className="far fa-long-arrow-right" /></Link>
                <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg.jpg)'}} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-item wow fadeInUp delay-0-6s">
                <div className="icon">
                  <i className="flaticon-focus" />
                </div>
                <h3><Link to="/services/vector-conversion">Vector Conversion</Link></h3>
                <p>Transforming concepts into vectors. Explore our vector conversion service, where ideas take
shape with crisp lines and flawless details. Redefine your graphics with precision and clarity.</p>
                <Link to="/services/vector-conversion" className="read-more">Explore <i className="far fa-long-arrow-right" /></Link>
                <div className="bg-image" style={{backgroundImage: 'url(images/services/service-bg.jpg)'}} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
      <Link to="https://login.digitize4life.com/register.asp" className="theme-btn hover-two mt-20 mb-3">Get started<i className="far fa-long-arrow-right" /></Link>
      </div>
    </div>
  </div>
</div>

    )
}