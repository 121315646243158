import Slider from "react-slick";

export const Testimonials=()=>{
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000
  };
    return(
        <section className="testimonials-area pt-40">
  <div className="container rel">
    <div className="row justify-content-between pb-35">
      <div className="col-lg-5">
        <div className="section-title mb-20 wow fadeInRight delay-0-2s">
          <span className="sub-title mb-10">Testimonials</span>
          <h2>See the Stories of Delight from Our Valued Clients</h2>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="global-clients mb-20 wow fadeInLeft delay-0-2s">
          <h5>Global Clients</h5>
          <div className="images">
            <img src="images/testimonials/global-client2.jpg" alt="Global client" />
            <img src="images/testimonials/global-client3.jpg" alt="Global client" />
            <img src="images/testimonials/global-client4.jpg" alt="Global client" />
            <img src="images/testimonials/global-client5.jpg" alt="Global client" />
            <img src="images/testimonials/global-client6.jpg" alt="Global client" />
            <img src="images/testimonials/global-client7.jpg" alt="Global client" />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
     <div className="col-md-6">
     <div className="testimonial-item">
    <div className="image">
          <img src="images/Home/8th section left side.png" alt="Testimonial" />
        </div>
    </div>
     </div>
     <div className="col-md-6">
     <Slider {...settings} className="textimon">
     <div className="testimonial-item">
     <div className="content">
          <div className="text">
          They are awesome in custom patches and embroidery digitizing services … I highly recommend
digitize4life...
          </div>
          <div className="author">
            <img src="images/Trustpilot.png" alt="Author" />
            <div className="title">
              <h5>Mark Diaz</h5>
             {/* <span>Senior Manager</span> */}
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content">
          <div className="text">
          Positive: Quality
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>Zamani Sicard</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          I am so happy with the digitizing
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Chris</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          You guys are amazing
          </div>
          <div className="author">
            <img src="images/Instagram.png" alt="Author" />
            <div className="title">
              <h5>Auto Drip Clothing</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Wonderful job, I really like their efforts on my raster images. Highly recommended digitize4life!
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>R.P. /G\</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          The digitizing was good quality
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Jeryn</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Great work and turn around.
          </div>
          <div className="author">
            <img src="images/Instagram.png" alt="Author" />
            <div className="title">
              <h5>Steptoe</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Thanks you so much, you do excellent work i will be sending more your way !!!!
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>Ghulam Sarwar</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Committed to work and delivered logos on time
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Bob</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Nice job man
          </div>
          <div className="author">
            <img src="images/Instagram.png" alt="Author" />
            <div className="title">
              <h5>Jets Embroidery</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Thanks, this has been so easy! Everything came right to me – digitizing design proofs to my email. We
LOVE everything.
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Hillary Jenks</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          The designs are great! Thank you for all of your hard work! Please tell your team that they ROCK!
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>Kristina McMann</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          You guys are very efficient and deliver a nice product in a timely fashion. Thank you!!
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Cathie Putnam</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          They are pro professionals and did quality work! They were very helpful as I was new to this and I have
got great feedback on my orders from my customers which was done and delivered on time because of
digitize4life
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>Reginald Scantlebury</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          A first-class brochure! Thanks so much for your patience, and your beautiful work on the brochure!
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Wendy Beltinck</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Jason over at digitize 4life knocked my designs out of the park. Not only that but anytime I has a
question he immediately responded. We will definitely be doing more work with this crew
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>Taos Ford</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          You guys are very efficient and deliver a nice product in a timely fashion. Thank you!!
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Cathie Putnam</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          Jason @ Digitize 4 life has been nothing but helpful in every way with the process of our order! From
beginning to the end.. We came for the quality but will be staying for much for now! Highly
Recommended! Thanks Again for everything!
          </div>
          <div className="author">
            <img src="images/Google.png" alt="Author" />
            <div className="title">
              <h5>POE Brand</h5>
            </div>
          </div>
        </div>
     </div>
     <div className="testimonial-item">
     <div className="content ">
          <div className="text">
          A first-class brochure! Thanks so much for your patience, and your beautiful work on the brochure!
          </div>
          <div className="author">
            <img src="images/GURU.png" alt="Author" />
            <div className="title">
              <h5>Wendy Beltinck</h5>
            </div>
          </div>
        </div>
     </div>
     </Slider>
</div>
    </div>
    <div className="circle-shapes">
      <div className="shape-inner">
        <span className="dot-one" />
        <span className="dot-two" />
        <span className="dot-three" />
        <span className="dot-four" />
        <span className="dot-five" />
      </div>
    </div>
  </div>
</section>

    )
}