import { Link } from "react-router-dom";
import { CTAArea } from "../Landing/CTAArea"
import {Helmet} from "react-helmet";
import { Pricing } from "../Landing/Pricing";

export const PricingPage=()=>{
    return(
        <>
        <Helmet>
        <title>Transparent Pricing for Premium Embroidery Digitizing, Vector Conversion, and Custom Patches</title>
        <meta name="description" content="Explore our competitive pricing packages for top-notch Embroidery Digitizing, Vector Conversion,
and Custom Patch services. Quality craftsmanship meets affordability for your unique needs." />
        <meta name="keywords" content=""/>
        <link rel="canonical" href="https://digitize4life.com/pricing"></link>
     </Helmet>

        <section className="page-banner bgs-cover text-white pt-65 pb-40" id="page-banner" style={{backgroundImage: 'url(images/Pricingpagecover.png)'}}>
<div className="container">
  <div className="banner-inner mt-65">
    <h1 className="page-title wow fadeInUp delay-0-2s" style={{color:'#000'}}>Pricing</h1>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wow fadeInUp delay-0-4s">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">Pricing</li>
      </ol>
    </nav>
  </div>
</div>
</section>
        <Pricing para="Choose Digitize4life for pocket-friendly prices without compromising on quality. From custom patches to
precise vector conversions, our services cater to your unique needs. Unleash your creativity, and let us bring
your designs to life with precision and affordability."/>
        <CTAArea/>
        </>
    )
}